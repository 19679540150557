#timer-play {
  width: 100vw;
  user-select: none;
}

#timer-play #timer-details-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#timer-play .cycles-list .cycle-list-item:not(:last-child) {
  margin-bottom: 4px;
}

#empty-timer-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

@media (orientation: portrait) {
  #timer-play {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 5rem);
  }
}

@media (orientation: landscape) {
  #timer-play {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: var(--color-gray-dark);
  }

  #timer-play #timer-details-container {
    max-width: calc(100vw - 82px);
    overflow: hidden;
  }
}
