#timer-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0;
}

#timer-list .seconds-row {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding: 1rem 0;
  max-height: calc(100% - 6rem);
}

#timer-list .seconds-row img {
  max-width: 100%;
  max-height: 35vh;
}

#timer-list .seconds-row p {
  height: 18vh;
  overflow: hidden;
  overflow-y: auto;
  font-size: 1rem !important;
  padding: 0 1rem;
  margin: 0;
}

#timer-list .big-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

#timer-list .small-time:before {
  content: ' / ';
}

#timer-list aside {
  display: inline;
}

#timer-list #time-details {
  display: flex;
  justify-content: space-around;
  font-size: 10vw;
}

#timer-list .big-name {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 1rem;
}

#timer-list #cycles-list {
  overflow: hidden;
}

@media (orientation: portrait) {
  #timer-list .big-time {
    font-size: 25vw;
  }

  #timer-list .big-name {
    font-size: 7vw;
  }

  #timer-list .small-time,
  #timer-list aside {
    font-size: 5vw;
  }

  #timer-list .seconds-row p {
    font-size: 5vw;
  }
}

@media (orientation: landscape) {
  #timer-list {
    max-width: calc(100vw - 56px);
  }

  #timer-list .big-time {
    font-size: 22vh;
  }

  #timer-list .big-name {
    font-size: 5vw;
    width: calc(100% - 4rem);
  }

  #timer-list .small-time,
  #timer-list aside {
    font-size: 5vh;
  }

  #timer-list .seconds-row p {
    font-size: 5vh;
  }
}
