.list {
  background: var(--color-light-background);
  width: 100%;
  overflow: hidden;
}

.list,
.list .mdc-list-item__meta {
  color: var(--color-light-background-text);
}

.list .mdc-list-item__secondary-text {
  text-align: left;
}

.timers-list {
  width: 100%;
  max-width: var(--dimensions-max-width);
}

.timers-list .list-item {
  display: flex;
  padding: 0.5rem;
  width: 100%;
}

.timers-list .list-item .time {
  text-align: center;
  width: 75px;
}

.timers-list .list-item .title {
  flex: 1;
  text-align: left;
  padding-left: 1rem;
  user-select: none !important;
}

.timers-list a {
  text-decoration: none;
}

.timers-list .interstitial {
  display: flex;
  align-items: center;
  color: var(--mdc-theme-primary);
  height: 1rem;
  padding: 1rem;
}

.timers-list .interstitial span {
  flex: 1;
  margin-top: -5px;
  text-align: center;
  margin-left: -24px;
}

.timers-list[has-local='true'][has-search='true'] .interstitial {
  border-top: 1rem solid var(--color-gray-dark);
}
