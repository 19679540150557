#period-sheet {
  position: fixed;
  background: var(--color-sheet-background);
  right: 0;
  left: 0;
  top: 100vh;
  height: 100vh;
  z-index: 1;

  transition: top 600ms;
}

#period-sheet[is-showing='true'] {
  top: 0;
}

#period-sheet[type='work'] .mdc-toolbar__row {
  background: var(--color-form-background-work);
}

#period-sheet[type='rest'] .mdc-toolbar__row {
  background: var(--color-dark-background);
}

#period-sheet .mdc-toolbar__row {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

#period-sheet .form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: auto;
}

#period-sheet[type='rest'] .form-wrapper {
  margin-top: -97px;
}

#period-sheet .form-wrapper form {
  max-width: 500px;
}

#period-sheet .form-wrapper form > div:not(:last-child) {
  margin-bottom: 1rem;
}

#period-sheet .row {
  display: flex;
  align-items: center;
}

#period-sheet .time {
  /* color: var(--color-sheet-color); */
  background: var(--color-gray-dark);
  margin-left: 1rem;
  padding: 16px;
  border-radius: var(--border-radius);
}

#period-sheet .image-upload-preview {
  border-color: var(--color-sheet-color);
  color: var(--color-sheet-color);
}

#period-sheet .mdc-button--raised {
  background-color: var(--color-sheet-color);
  color: var(--color-dark-background-text);
}

#period-sheet hr {
  border-color: var(--color-sheet-color);
}

#period-sheet .type-selection .mdc-button:not([disabled]) {
  opacity: 0.25;
}

#period-sheet .mdc-button.work {
  background: var(--color-form-background-work);
  color: var(--color-dark-background-text);
}

#period-sheet .mdc-button.rest {
  background: var(--color-dark-background);
  color: var(--color-dark-background-text);
}

#period-sheet .type-selection {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

#period-sheet .type-selection .mdc-button {
  width: calc(50% - 2rem);
}

/* Input Styles */
#period-sheet .row {
  padding: 0.5rem 0;
}

#period-sheet .mdc-text-field--textarea {
  padding-top: 1rem;
}

#period-sheet .mdc-text-field--textarea .mdc-floating-label {
  top: 2rem;
  left: 1rem;
}

#period-sheet .mdc-text-field {
  background: var(--color-form-input-background);
  width: 100%;
}
