.phone-mock {
  position: relative;
  border-color: black;
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: var(--border-width);

  max-width: 250px;

  --color-mock: #222;
  --speaker-width: 100px;
  --speaker-height: 10px;
  --border-width: 50px 15px 40px 15px;
  --border-radius: 30px;
  --speaker-top: -30px;
}

.phone-mock .camera {
  position: absolute;
  top: var(--speaker-top);
  width: var(--speaker-height);
  height: var(--speaker-height);
  border-radius: 50%;
  background: var(--color-mock);
}

.phone-mock .left-camera {
  left: calc(2 * var(--speaker-height));
}

.phone-mock .right-camera {
  left: calc(4 * var(--speaker-height));
}

.phone-mock .speaker {
  position: absolute;
  left: 50%;
  margin-left: calc(-0.5 * var(--speaker-width));
  width: var(--speaker-width);
  height: var(--speaker-height);
  border-radius: 10px;
  background: var(--color-mock);
}

.phone-mock .top-speaker {
  top: var(--speaker-top);
}

.phone-mock .bottom-speaker {
  bottom: var(--speaker-top);
}

.phone-mock .screen {
  margin-bottom: -5px;
}
