.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.page h1 {
  font-size: 10vw;
  margin: 1rem 0 0;
}

.page h1,
.page h2 {
  text-align: center;
}

.page h2 {
  font-size: 5vw;
  margin: 1rem;
}

.page hr {
  width: 100%;
}

.page section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  width: 101vw;
  max-width: var(--dimensions-max-width);
}

.page p {
  width: calc(100vw - 2rem);
  max-width: var(--dimensions-max-width);
}
