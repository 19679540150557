#timer-edit {
  position: relative;
  overflow: hidden;
  user-select: none;
}

#timer-edit[is-owned='false'] form {
  opacity: 0.1;
  pointer-events: none;
}

#timer-edit[is-owned='false']:before {
  position: absolute;
  top: 33vh;
  left: 0;
  right: 0;

  content: 'Copying...';
  display: block;
  text-align: center;
  background: var(--mdc-theme-primary-dark);
  padding: 2rem;
  z-index: 1;
}

#timer-edit form {
  max-width: 500px;
  width: calc(100vw - 2rem);
}

#timer-edit .flex {
  flex: 1;
}

#timer-edit hr {
  margin: 2rem 0;
}

#timer-edit .buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
}

#timer-edit .error {
  visibility: hidden;
  color: var(--color-form-error);
  background: var(--color-form-input-background);
  padding: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

#timer-edit .error[error] {
  visibility: visible;
}

#timer-edit .period-wrapper {
  display: flex;
}

#timer-edit .mdc-list-item {
  display: flex;
  position: relative;
  color: var(--color-form-list-color);
  padding: 1rem;
}

#timer-edit .time {
  margin-right: 1rem;
}
#timer-edit .buttons .mdc-icon-button {
  margin: 0 0.25rem;
}

#timer-edit .row.buttons {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 5rem;
}

#timer-edit .row.buttons .mdc-button {
  min-width: 7rem;
  margin-bottom: 1rem;
}
