#settings form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 4rem);
  padding: 2rem;
  max-width: 500px;
}

#settings .switch {
  padding: 1rem;
}

#settings .radio-button {
  margin-left: -1rem;
}

#settings .mdc-text-field {
  width: 100%;
}

#settings input {
  color: var(--color-gray-light) !important;
}

#settings .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
#settings .mdc-text-field-helper-text {
  color: var(--color-gray-light);
}

#settings .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
#settings .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
#settings .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--color-gray-light);
}

#settings .mdc-text-field--focused .mdc-floating-label {
  color: var(--mdc-theme-secondary) !important;
}

#settings hr {
  height: 2rem;
  border: none;
}

#settings .mdc-form-field {
  width: 100%;
}

#settings .mdc-form-field label {
  color: var(--color-gray-light);
  margin-left: 1rem;
}
