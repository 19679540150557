#landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#landing h1 {
  font-size: 15vw;
  margin: 1rem 0 0;
}

#landing img,
#landing iframe {
  max-width: 100%;
}

#landing .mdc-fab {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

#landing .user-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

#landing .cta {
  justify-content: center;
  min-height: 25vh;
}

#landing li {
  padding: 0.25rem 1rem;
}

#landing h1,
#landing h2 {
  text-align: center;
}

#landing h2 {
  font-size: 8vw;
}

#landing hr {
  width: 100%;
}

#landing section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  width: calc(100vw - 2rem);
  max-width: var(--dimensions-max-width);
}

#landing p,
#landing ul,
#landing .user-actions {
  max-width: 500px;
}

#landing .user-actions a {
  width: 100%;
}

#landing .mdc-list {
  color: var(--mdc-theme-primary-text);
  width: 100%;
}

#landing .mdc-list-item__graphic {
  color: var(--mdc-theme-primary-text);
  font-style: initial;
  font-size: 1.5rem;
}

#landing .mdc-list a {
  text-decoration: none;
}

#landing .phone-mock {
  margin: 2rem 0;
}
