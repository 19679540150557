#timer-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100vw;
  max-width: 30rem;
  padding-bottom: 5rem;

  --background-color: var(--color-dark-background);
  --mdc-theme-secondary: rgba(0, 0, 0, 0.9);
}

#timer-details h1 {
  font-size: 10vw;
  line-height: 5rem;
  margin: 0 0 2rem;
  white-space: nowrap;
}

@media (min-width: 720px) {
  #timer-details h1 {
    font-size: 5rem;
  }
}

#timer-details .timer-details-list {
  display: flex;
  justify-content: space-around;
  width: calc(100% - 2rem);
  padding: 1rem;
  margin: 0;
  background: var(--background-color);
}

#timer-details .timer-details-list li {
  list-style: none;
}

#timer-details .timer-details-list span:not(:last-of-type) {
  margin-right: 0.5rem;
}

#timer-details p {
  padding: 1rem;
  width: calc(100% - 2rem);
  margin: 0;
  background: var(--background-color);
}

#timer-details img {
  padding-top: 2rem;
  max-width: 100%;
}

#timer-details .mdc-fab.edit {
  position: relative;
  top: 28px;
  right: -30%;
  z-index: 1;
}

#timer-details .markdown {
  width: 100%;
}
