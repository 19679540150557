#flash {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  animation: pulse 1s infinite;

  background: white;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
