.image-upload-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.image-upload-input[is-loading='true'] {
  pointer-events: none;
}

.image-upload-input .mdc-linear-progress {
  position: absolute;
  top: 0;
  z-index: 1;
}

.image-upload-input[is-loading='true']:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 1;
}

@media (max-width: 450px) {
  .image-upload-input {
    flex-direction: column;
  }

  .image-upload-input .mdc-button {
    margin-bottom: 1rem;
  }
}

.image-upload-input input {
  display: none;
}

.image-upload-input .image-upload-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 10rem;
  width: 10rem;
  border: 1px dashed var(--color-form-outline);
  padding: 0.5rem;
}

.image-upload-input img.constrained-size {
  max-width: 100%;
  max-height: 100%;
}

.image-upload-input img.full-size {
  position: absolute;
  display: none;
}

.image-upload-input canvas {
  display: none;
}

.image-upload-input .mdc-icon-button {
  position: absolute;
  bottom: 1rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.image-upload-input .rotate-button {
  left: 1rem;
}

.image-upload-input .delete-button {
  right: 1rem;
}
