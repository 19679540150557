.cycles-list {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.cycles-list .cycle-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cycles-list .cycle-list-item:not(:last-child) {
  margin-bottom: 4px;
}

.cycles-list .cycle-list-item .cycle-index {
  text-align: center;
  width: 10vw;
  max-width: 5rem;
  min-width: 2rem;
  padding: 0.5rem;
  color: var(--mdc-theme-primary-dark);
  font-size: 2rem;
  font-weight: bold;
}

.cycles-list .period-list {
  flex: 1;
  padding: 0;

  min-height: 4rem;
  display: block;
  display: flex;
  flex-direction: column;
}

.cycles-list .period-list .period-list-item {
  flex: 1;
  display: flex;
  justify-content: center;

  position: relative;

  align-items: center;
  padding: 0.5rem;
  font-size: 1rem;
}

.cycles-list .period-list .period-list-item .period-name {
  flex: 1;
  text-align: left;
  padding-left: 1rem;
}

.cycles-list .period-list .period-list-item .time {
  text-align: right;
  padding-right: 1rem;
}

.cycles-list .period-list .period-list-item img {
  position: absolute;
  left: -23.5px;
  /* max-height: 48px; */
  max-width: 48px !important;
  padding: 0 !important;
  clip-path: circle(14px at center);
  color: transparent;
}
