.chromecast-base .cycles-list .period-list .period-list-item {
  /* padding-right: 1rem; */
}

@media (orientation: landscape) {
  #timer-details-container,
  #timer-bars,
  #timer-list {
    max-width: 100vw !important;
  }
}
