#reddit-ads {
  --background-inset: 32px;
}

#reddit-ads h2 {
  font-size: 5rem;
  text-align: center;
}

#reddit-ads .grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

#reddit-ads .grid > * {
  margin: 2rem;
}

#reddit-ads img {
  max-width: 100%;
}

#reddit-ads .desktop .phone-mock {
  max-width: 260px;
}

#reddit-ads .mobile .phone-mock {
  max-width: 110px;
}

#reddit-ads .ad {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: var(--mdc-theme-primary);
  padding: 1rem;
}

#reddit-ads .desktop {
  width: calc(1200px - var(--background-inset));
  height: calc(628px - var(--background-inset));
}

#reddit-ads .mobile {
  width: calc(400px - var(--background-inset));
  height: calc(300px - var(--background-inset));
}

#reddit-ads .mobile .phone-mock {
  --speaker-width: 40px;
  --speaker-height: 5px;
  --border-width: 20px 10px 15px 10px;
  --border-radius: 15px;
  --speaker-top: -10px;
}

#reddit-ads .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 2rem;
  text-align: center;
}

#reddit-ads .desktop .text {
  font-size: 4rem;
}

#reddit-ads .mobile .text {
  font-size: 2rem;
}

#reddit-ads .mobile p {
  font-size: 1.25rem;
}

#reddit-ads h3 {
  margin: 0 0 1rem;
}

#reddit-ads p {
  margin: 0.75rem 0
}

