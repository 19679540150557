.list-item-wrapper {
  position: relative;
  max-width: calc(100% - 61px);
  width: 100%;
}

.period-wrapper .period-controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: space-around;
  align-items: center;

  background: rgba(255, 255, 255, 0.75);
  cursor: default;
  opacity: 1;
}

.period-wrapper[is-only='true'][is-active='false']:not(:focus-within) .title:before {
  content: 'Click To Edit';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.75);
  pointer-events: none;
}

.period-wrapper[is-active='false'] .period-controls:not(:focus-within) {
  opacity: 0;
  z-index: -1;
}

.period-wrapper .mdc-icon-button[is-hidden='true'] {
  visibility: hidden;
}

.period-wrapper[is-first='true'] .mdc-list-item {
  border-top-left-radius: var(--border-radius);
}

.period-wrapper[is-last='true'] .mdc-list-item {
  border-bottom-left-radius: var(--border-radius);
}

.period-wrapper[is-first='true'][is-last='true'] .mdc-list-item {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.period-wrapper .mdc-list-item[type='prepare'] {
  background: var(--color-form-background-prepare);
}

.period-wrapper .mdc-list-item[type='prepare'] .mdc-icon-button {
  visibility: hidden;
}

.period-wrapper .mdc-list-item[type='rest'] {
  background: var(--color-form-background-rest);
}

.period-wrapper .mdc-list-item[type='work'] {
  background: var(--color-form-background-work);
}

.period-wrapper .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.period-wrapper .buttons {
  padding-left: 0.25rem;
  border: var(--button-border-width) solid var(--button-border-color);
  border-width: var(--button-border-width) var(--button-border-width) 0 0;
}

.period-wrapper[is-first='true'] .buttons {
  border-top-right-radius: var(--border-radius);
}

.period-wrapper[is-last='true'] .buttons {
  border-bottom-right-radius: var(--border-radius);
}

.period-wrapper[is-last='true'] .buttons {
  border-bottom-width: var(--button-border-width);
}

.period-wrapper img {
  max-width: 75px;
  max-height: 100%;
  margin: 0 1rem;
}

.period-wrapper .buttons .mdc-checkbox {
  margin: 0 0.5rem;
}

#check-all-wrapper .mdc-checkbox {
  margin: 0 9px;
}

#check-all-wrapper {
  display: flex !important;
  justify-content: flex-end !important;
}

#check-all-wrapper[can-multi-select='false'] {
  visibility: hidden;
}

.period-wrapper .add-button-wrapper {
  position: relative;
}

.period-wrapper .add-button-wrapper aside {
  position: absolute;
  top: 6px;
  left: calc(-200% - 0.5rem);
  background: var(--mdc-theme-secondary);
  border-radius: var(--border-radius);
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  margin-left: -100%;
  white-space: nowrap;
}

.period-wrapper .add-button-wrapper aside {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  5%,
  35%,
  65%,
  95% {
    transform: translate3d(-1px, 0, 0);
  }

  10%,
  30%,
  40%,
  60%,
  70%,
  90% {
    transform: translate3d(2px, 0, 0);
  }

  15%,
  25%,
  45%,
  55%,
  75%,
  85% {
    transform: translate3d(-4px, 0, 0);
  }

  20%,
  50%,
  80% {
    transform: translate3d(4px, 0, 0);
  }
}
