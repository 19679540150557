.canvas-wrapper {
  position: relative;
}

.canvas-overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  text-transform: uppercase;
}

.canvas-overlay > div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (orientation: portrait) {
  .canvas-overlay {
    font-size: 4vw;
  }
}

@media (orientation: landscape) {
  .canvas-overlay {
    font-size: 5vh;
  }
}
