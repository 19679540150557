#timer-controls {
  display: flex;

  padding: 0.5rem 1rem 0rem;
  background: var(--color-gray-light);
}

#timer-controls [disabled] {
  visibility: hidden;
}

@media (orientation: portrait) {
  #timer-controls {
    justify-content: space-around;
    align-items: center;
  }
}

@media (orientation: landscape) {
  #timer-controls {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}
