#timer-bars {
  max-width: 100vw;
  overflow: hidden;
}

#timer-bars > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

@media (orientation: portrait) {
  #timer-bars {
    height: 20vh;
  }
}

@media (orientation: landscape) {
  #timer-bars {
    height: 20vh;
    max-width: calc(100vw - 56px);
  }
}
